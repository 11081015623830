import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  ForgeAutocomplete,
  ForgeButton,
  ForgeButtonArea,
  ForgeExpansionPanel,
  ForgeIcon,
  ForgeOpenIcon,
  ForgeTextField
} from '@tylertech/forge-react';
import {
  AutocompleteFilterCallback,
  IAutocompleteOption,
  IAutocompleteSelectEventData
} from '@tylertech/forge/esm/autocomplete';
import I18n from 'common/i18n';
import { Filter } from 'accessibleBrowseFilters/types';
import { getParamValueInUrl } from 'accessibleBrowseFilters/helpers';

interface Props {
  fieldInfo: Filter;
  isMobile: boolean;
  onAutocompleteSelect: (filterValue: string) => void;
  onClear: () => void;
  selectedTag?: string;
}

const AutocompleteField: FunctionComponent<Props> = (props) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isPanelExpanded, setIsPanelExpanded] = React.useState<boolean>(true);
  const [tags, setTags] = useState<IAutocompleteOption[]>([]);
  const scope = 'controls.browse.browse3.filter';
  const isAnySelected = getParamValueInUrl(props.fieldInfo.param);
  const expansionPanelRef = useRef(null);
  useEffect(() => {
    // Hack to get animations to turn back on just in time for the first expansion panel click
    setTimeout(() => {
      if (expansionPanelRef.current) {
        // @ts-expect-error TS(2339) FIXME: Property 'useAnimations' does not exist on type 'n... Remove this comment to see the full error message
        expansionPanelRef.current.useAnimations = true; // useAnimations is on the ExpansionPanel
      }
    });
    setTags(mapTagsToAutocompleteOptions());
  }, []);

  let tagInUrl: string | null;
  if (props.selectedTag) {
    tagInUrl = props.selectedTag;
  } else {
    tagInUrl = getParamValueInUrl(props.fieldInfo.param) ?? '';
  }
  if (tagInUrl !== inputValue) setInputValue(tagInUrl);

  const mapTagsToAutocompleteOptions = () => {
    // extra_options holds all the tags, options only holds the top 5
    const options = props.fieldInfo.extra_options ? props.fieldInfo.extra_options : props.fieldInfo.options;
    const autocompleteOptions = options.map((option) => {
      return {
        label: option.text,
        value: option.value
      };
    });
    return autocompleteOptions;
  };

  const onFilter: AutocompleteFilterCallback = (filterText) => {
    return tags.filter((opt) => opt.label.toLowerCase().includes(filterText.toLowerCase()));
  };

  const onAutocompleteSelect = ({ detail: { value } }: CustomEvent<IAutocompleteSelectEventData>) => {
    setInputValue(value);
    props.onAutocompleteSelect(value);
  };

  const handleClearClick = (e: Event) => {
    e.stopPropagation();
    setInputValue('');
    props.onClear();
  };

  const panelAttrTitle = `${props.fieldInfo.title}`.trim().toLowerCase().replace(/[ ]+/gi, '-');
  let autocompleteId = props.fieldInfo.param + '-input';
  if (props.isMobile) {
    autocompleteId = autocompleteId + '-mobile';
  }

  // Note: "Toogle panel" hard-coded string is never actually displayed to the user, but is required to pass
  // accessibility testing (no empty buttons allowed). See documentation on ButtonArea for more info
  // https://forge.tylerdev.io/main/?path=/docs/components-button-area--default
  return (
    <div className="filter-expansion-panel">
      <ForgeExpansionPanel
        key={props.fieldInfo.title}
        ref={expansionPanelRef}
        useAnimations={false}
        open={isPanelExpanded}
        openCallback={() => setIsPanelExpanded(true)}
        closeCallback={() => setIsPanelExpanded(false)}
      >
        <ForgeButtonArea slot="header">
          <button
            slot="button"
            type="button"
            data-testid={'toggle-' + panelAttrTitle + '-expansion-panel'}
            id={'toggle-' + panelAttrTitle + '-expansion-panel'}
            aria-controls={panelAttrTitle + '-expansion-panel-content'}
            aria-expanded={isPanelExpanded}
          >
            Toggle panel
          </button>
          <div className="expansion-panel-header">
            <div className="expansion-panel-header-title forge-typography--subtitle1-secondary">
              {props.fieldInfo.title}
            </div>
            <div className="expansion-panel-header-right-side">
              {isAnySelected && (
                <ForgeButton
                  type="flat"
                  onClick={handleClearClick}
                  data-testid={'clear-' + props.fieldInfo.title + '-button'}
                  data-forge-ignore
                >
                  <button type="button">
                    <span>{I18n.t('controls.browse.browse3.filter.clear_filters')}</span>
                  </button>
                </ForgeButton>
              )}
              <ForgeOpenIcon />
            </div>
          </div>
        </ForgeButtonArea>
        <div id={panelAttrTitle + '-expansion-panel-content'} className="expansion-panel-content">
          <ForgeAutocomplete
            multiple={false}
            mode={'stateless'}
            filter={onFilter}
            on-forge-autocomplete-select={onAutocompleteSelect}
          >
            <ForgeTextField>
              <input type="text" id={autocompleteId} data-testid={autocompleteId} defaultValue={inputValue} />
              <label htmlFor={autocompleteId} slot="label">
                {I18n.t('choose_tags', { scope })}
              </label>
              <ForgeIcon slot="trailing" data-forge-dropdown-icon name="arrow_drop_down" />
            </ForgeTextField>
          </ForgeAutocomplete>
        </div>
      </ForgeExpansionPanel>
    </div>
  );
};

export default AutocompleteField;
